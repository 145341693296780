import { Component, OnInit, Input } from '@angular/core';
import { SidenavMenuService } from './sidenav-menu.service';
import { Data, AppService } from '../../../app.service';
import { SidenavMenu } from './sidenav-menu.model';
import { TranslateService} from "@ngx-translate/core";
export const sidenavMenuItems = []
@Component({
  selector: 'app-sidenav-menu',
  templateUrl:'./sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
  providers: [ SidenavMenuService ]
})
export class SidenavMenuComponent implements OnInit {
  @Input('menuItems') menuItems;
  @Input('menuParentId') menuParentId;
  parentMenu:Array<any>;
  public getonetime=0;
  
  constructor(public translate: TranslateService,private sidenavMenuService:SidenavMenuService,public appService:AppService ) { }

  ngOnInit() 
  {
    this.getSidenavMenuItems();    
    if( this.menuItems !=undefined &&  this.menuItems !=null)
    {
      this.parentMenu = this.menuItems.filter(item => item.parentId == this.menuParentId);
    }
   
  }
  
  public getSidenavMenuItems()
    {
      var Home = 'Home'; 
      this.translate.get('lbl_New_side_menu_home').subscribe(( res: string ) => 
      {
        Home=res;
      }); 
      var AboutUs="About Us"
      this.translate.get('lbl_New_aboutus_nav_title').subscribe(( res: string ) => 
      {
        AboutUs=res;
      }); 
    var Video="Video"
      this.translate.get('lbl_New_video_nav_title').subscribe(( res: string ) => 
      {
        Video=res;
      }); 
      var subscription="subscription"
      this.translate.get('lbl_New_side_menu_productsubscription').subscribe(( res: string ) => 
      {
        subscription=res;
      }); 
      var store="store-list"
      this.translate.get('lbl_New_StoreList_Nav_title').subscribe(( res: string ) => 
      {
        store=res;
      }); 
      //store-list
    var ContactUs="Contact Us"
    this.translate.get('lbl_New_contactus_btn_save').subscribe(( res: string ) => 
    {
      ContactUs=res;
    }); 
    
        sidenavMenuItems.push(new SidenavMenu (1, Home, '/', null, null, false, 0))
        sidenavMenuItems.push(new SidenavMenu (2, AboutUs, '/about-us', null, null, false, 0))
       
      
        
        if(this.appService.IscheckOneTime==0)
        {
          
          this.appService.GetMainandSubCategory().subscribe(data => 
            {
              if(data[0].Success=="1")
              {     
                this.appService.lstCategories=data[0].result;
                this.appService.IscheckOneTime=1;   
                 
                  for (let i = 0; i < data[0].result.length; i++) 
                  {
                    
                     
                      var cCategoryName=data[0].result[i].cCategoryName;
                      var nCategoryId=data[0].result[i].nCategoryId;                 
                       if(data[0].result[i].SubCategory !=null)
                        {
                          
                          if(data[0].result[i].SubCategory !="[]")
                          {
                            sidenavMenuItems.push(new SidenavMenu (nCategoryId, cCategoryName, null, null, null, true,0))
                          }
                          else
                          {
                            sidenavMenuItems.push(new SidenavMenu (nCategoryId, cCategoryName, 'products/'+cCategoryName+'/Category', null, null, false,0))
                          }
                        }
                        else
                        {
                          sidenavMenuItems.push(new SidenavMenu (nCategoryId, cCategoryName, 'products/'+cCategoryName+'/Category', null, null, false,0))
                        }
    
    
                    
                     if(data[0].result[i].SubCategory !=null)
                       {
                         for (let j = 0; j < data[0].result[i].SubCategory.length; j++) 
                          {
                            var SubCategoryName=data[0].result[i].SubCategory[j].cCategoryName;
                            var nSubCategoryId=data[0].result[i].SubCategory[j].nCategoryId;
                            sidenavMenuItems.push(new SidenavMenu (nSubCategoryId, SubCategoryName, 'products/'+SubCategoryName+'/Category', null, null, false, nCategoryId))
                          }
    
                         
                      }
                  }
                  sidenavMenuItems.push(new SidenavMenu (4, subscription, '/subscription', null, null, false, 0))
                  sidenavMenuItems.push(new SidenavMenu (5, Video, '/Categories/video', null, null, false, 0))
                  sidenavMenuItems.push(new SidenavMenu (6, store, '/store-list', null, null, false, 0))
                  sidenavMenuItems.push(new SidenavMenu (7, ContactUs, '/contact', null, null, false, 0))
                  this.menuItems=sidenavMenuItems;
                  this.parentMenu = this.menuItems.filter(item => item.parentId == this.menuParentId);
               }
            });
        }
    }

  
  onClick(menuId){
    this.sidenavMenuService.toggleMenuItem(menuId);
    this.sidenavMenuService.closeOtherSubMenus(this.menuItems, menuId);    
  }

  

}
