import { SidenavMenu } from './sidenav-menu.model';
import { Data, AppService } from '../../../app.service';

const side=[];




export const sidenavMenuItems = [ 
    
]

export class SidenavMenuService 
{
    constructor(public appService:AppService){ } 

    
    

  
}




