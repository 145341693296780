import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public lat: number = 29.378586;
  public lng: number = 47.990341;
  public zoom: number = 12;
  public nCustomerId:any;
  Location:any;
  EmailAddress:any;
  SupportEnquiry:any;
  Comolains:any;
  WhatappNo:any;
  public ContentDetails:any;
  constructor(public appService:AppService) { }

  ngOnInit() 
  {
     this.nCustomerId=localStorage.getItem('nCustomerId');
     this.Location="Tahani Flowers Online";
     this.EmailAddress="orders@tahaniflowers.com";
     localStorage.setItem("EmailAddress",this.EmailAddress);
     this.SupportEnquiry="+96566319497";
     localStorage.setItem("SupportEnquiry",this.SupportEnquiry);
     this.WhatappNo="+96566075644";
     this.Comolains="+96566319497";
     //this.getSelectContentByPageName();
  }

  subscribe(){ }

  
  public getSelectContentByPageName()
  {


    this.appService.getContactUSDetails("CONTACT US").subscribe(data=>{
      const index: number = this.appService.Data.cartList.indexOf(data[0].Result);      
      if(data[0].Success=="1")
      {
      
        var cdaya='';
        //data[0].Result.length
        for (var i = 0; i < data[0].Result.length; i++) 
        {
          if(i==0)
          {
            this.Location=data[0].Result[i].cPageContent;
          }
          if(i==1)
          {
            this.EmailAddress=data[0].Result[i].cPageContent;
            localStorage.setItem("EmailAddress",data[0].Result[i].cPageContent);
          }
          if(i==2)
          {
            this.SupportEnquiry=data[0].Result[i].cPageContent;
            localStorage.setItem("SupportEnquiry",data[0].Result[i].cPageContent)
          }
          if(i==3)
          {           
            this.WhatappNo=data[0].Result[i].cPageContent;
          }
          if(i==4)
          {
            this.Comolains=data[0].Result[i].cPageContent;
          }
          var cdat="<mat-icon color='primary' class='mat-icon-lg'>home</mat-icon>";
          cdaya +="<div fxFlex='100' fxFlex.gt-xs='33.3'><div fxLayout='column' fxLayoutAlign='center center' class='text-center'><h3 class='primary-text py-1'>"+data[0].Result[i].cPageTitle+" :</h3><span class='text-muted'>"+data[0].Result[i].cPageContent+"</span></div></div>";
        }
        this.ContentDetails=cdaya;
      }
     
    });
  }

}