import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data, AppService } from '../../app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService} from "@ngx-translate/core";
import { Product, ProductDetails,cartlist, ResponseData_Wishlist, Wishlist } from '../../app.models';
//import {ProductsComponent} from '../../pages/products/products.component';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  @Input() product: cartlist;
  @Input() type: string;
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
  public count:number = 1;
  public nQuantity=1;
  public align = 'center center';
  public nCustomerId:any;

  //public IsWishList=1;

  constructor(public translate: TranslateService,public appService:AppService, public snackBar: MatSnackBar, private activatedRoute: ActivatedRoute,  private router: Router){}//,public productsComponent:ProductsComponent) { }
  //WishlistResponse:ResponseData_Wishlist[];

  ngOnInit() {
    if(this.product)
    {
      this.appService.GetLabelName("lbl_new_addPickup");
      
      if(this.product.nQuantity > 0)
      {
        
        this.count = this.product.nQuantity;
        //this.appService.nQuantity=this.count ;
      }
        this.nCustomerId=localStorage.getItem('nCustomerId');
      
    }  
    
    this.layoutAlign(); 
  }

  public layoutAlign()
  {
   
    if(this.type == 'all'){
      this.align = 'space-between center';
    }
    else if(this.type == 'wish'){
      this.align = 'start center';
    }
    else{
      this.align = 'center center';
    }
  }

  

  

  public IsChecAddkWishList=localStorage.getItem("IswishList");

  // public addToWishList(product:Product){
  //   this.appService.addToWishList(product);
  // }
 
  public addToWishList(product:ProductDetails)
  {
   
    //this.IsWishList=0;
   
    let nWishlistId: number;
    let message, status;

    let _Type=localStorage.getItem('_Type') ;
    let nSupplierId=0;
    if(_Type=='Category')
      nSupplierId=product.nSupplierId; 
    else  
      nSupplierId=Number.parseInt(localStorage.getItem('nSupplierId'));
      
      let nCustomerId=localStorage.getItem('nCustomerId');
    this.appService.addWishlistData(nCustomerId,product.nProductId,nSupplierId).subscribe(data => {
     if(data[0].Success == '1')
      {
        
        //this.IsChecAddkWishList=1;
        this.product.IswishList=1;
        //message = 'The product ' + product.cName + ' has been added to wish list.';  //data[0].Message; 
        //lbl_New_The_product_has_been_added_to_cart
        
       
        this.translate.get('lbl_New_productdetails_add_product_wishlist').subscribe(( res: string ) => 
          {
           
            message= product.cName +" "+ res;
        }); 

        status = 'success'; 
        
        
        
        ///product.
       this.appService.getWishlistByCustomerId(nCustomerId);      
        //location.reload();
      }
      else{
         // this.Data.wishList.push(ProductDetails);
         this.translate.get('lbl_The_product_already_added_to_wish_list').subscribe(( res: string ) => 
          {
           
            message= product.cName +" "+ res;
        }); 
          
          status = 'error';     
      }
      this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
      //this.WishlistResponse = data[0]; 
    
      
     
    });


    // this.appService.getWishlistByCustomer(this.appService.nCustomerId).subscribe(data=>{
     
      
    // });
    
  }

  public remove(product:ProductDetails) {
    this.appService.removeWishlistData(this.nCustomerId,product.nProductId).subscribe(data => {
    
        if(data.Success == '1')
        {
          
        let nCustomerId=localStorage.getItem('nCustomerId');   
        
        this.appService.getWishlistByCustomerId(nCustomerId);
        this.product.IswishList=0;
        var status = 'success'; 
        var message;
        this.translate.get('lbl_New_productdetails_remove_product_wishlist').subscribe(( res: string ) => 
        {
         
          message= product.cName +" "+ res;
      }); 
        
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
        //location.reload();
      }
     
});

   
}

  public addToCart(product: ProductDetails,cartType) {

   
    //let currentProduct:ProductDetails;
    let message, status;
    let nQuantity = this.nQuantity;//product.nQuantity;
    // if(product.fFinalStock!=0){
    //   nQuantity=product.fFinalStock;
    // }


    let nCartType = 1; //nCart Type pass 1 (bouquet create time add cart type 0 and Add to cart and service product time add cart tupe 1)
    //let nSupplierid=1;//this.productsComponent._Id;
    let _Type = localStorage.getItem('_Type');
    let nSupplierId = 0;

    if (_Type == 'Category')
      nSupplierId = product.nSupplierId;
    else
      nSupplierId = Number.parseInt(localStorage.getItem('nSupplierId'));

    let cDeviceToken = localStorage.getItem('cDeviceToken');
    if (!cDeviceToken) {
      cDeviceToken = this.appService.GenerateDeviceToken();
      localStorage.setItem("cDeviceToken", cDeviceToken);
    }
    
    let nCustomerIdPass = 0;
    if (localStorage.getItem('nCustomerId')) {
      if (Number.parseInt(localStorage.getItem('nCustomerId')) > 0) 
      {
        nCustomerIdPass = Number.parseInt(localStorage.getItem('nCustomerId'));
        cDeviceToken = "";
      }
    }
    // var cLoginType=localStorage.getItem("LoginType");
    // if(cLoginType=="App")
    // {
    //   if (Number.parseInt(localStorage.getItem('nCustomerId')) > 0) 
    //   {
    //     nCustomerIdPass = Number.parseInt(localStorage.getItem('nCustomerId'));
    //     cDeviceToken = "";
    //   }
    // }
    // if(cLoginType=="Facebook" || cLoginType=="Google")
    // {
    //     cDeviceToken = this.appService.GenerateDeviceToken();
    //     nCustomerIdPass = 0;
    // }
    // if(cLoginType==undefined || cLoginType==null)
    // {
    //   cDeviceToken = this.appService.GenerateDeviceToken();
    // }
    let nBouquetTypeId=0;
    let nBouquetSizeId=0;
    var NewJsonAdd = [];
    NewJsonAdd.push({nProductId:product.nProductId,
      nQuantity:1});
    let cJsonServicesProducts=JSON.stringify(NewJsonAdd);
    var IsParent=0;
    if(cartType=='All')
    {
      IsParent=0;
    }
    else
    {
      IsParent=1;
    }
    let currentProduct = this.appService.Data.cartList.filter(item => item.nProductId == product.nProductId)[0];

    if (currentProduct) 
    {
      
      if ((currentProduct.nQuantity + this.count) <= currentProduct.fProductStock) 
      {
        product.cartCount = currentProduct.nQuantity + this.count; 
        
//CreateCartV2
        this.appService.CreateCartV2(nCustomerIdPass, product.nProductId, nQuantity, nCartType, cDeviceToken, 0,nBouquetTypeId,nBouquetSizeId,cJsonServicesProducts,IsParent).subscribe(data => {

          if (data[0].Success == '1') 
          {            
            this.translate.get('lbl_New_productdetails_add_product_message').subscribe((res: string) => {
            message = product.cName + " " + res;
              //message = product.cName + " " + res ;
            });
            var cCart;
            this.translate.get('lbl_New_view_cart_dialog_view_cart_btn').subscribe((res: string) => {
             
              cCart = res;
            });
            message = message;  //data[0].Message; 
            status = 'success';

            this.appService.getCart();

          }
          else {
            // this.Data.wishList.push(ProductDetails);
            this.translate.get('lbl_New_The_product_already_added_to_cart').subscribe((res: string) => {
             
              message = product.cName + " " + res;
            });
            var cCart;
            this.translate.get('lbl_New_view_cart_dialog_view_cart_btn').subscribe((res: string) => {
             
              cCart = res;
            });
            message = message;
            status = 'error';
          }
         // this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
         this.snackBar.open(message, cCart, { panelClass: [status], verticalPosition: 'top',  duration: 5000})
         .onAction()
         .subscribe(() => this.router.navigateByUrl('/cart')); 
         //this.WishlistResponse = data[0]; 


        });
      }
      else {

        this.translate.get('lbl_New_You_can_not_choose_more_items_than_available_In_stock').subscribe((res: string) => {

          message = res;
        });
        this.snackBar.open(message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        
        return false;
      }
    }
    else 
    {
      this.appService.CreateCartV2(nCustomerIdPass, product.nProductId, nQuantity, nCartType, cDeviceToken, 0,nBouquetTypeId,nBouquetSizeId,cJsonServicesProducts,IsParent).subscribe(data => {

        if (data[0].Success == '1') 
        {
          //message = 'The product ' + product.cName + ' has been added to cart.';  //data[0].Message; 
          this.translate.get('lbl_New_The_product_has_been_added_to_cart').subscribe((res: string) => {
          message = product.cName + " " + res;
           // message = product.cName + " " + res;
          });
          var cCart;
          this.translate.get('lbl_New_view_cart_dialog_view_cart_btn').subscribe((res: string) => {
           
            cCart = res;
          });
          status = 'success';

          this.appService.getCart();
        }
        else 
        {
          
          this.translate.get('lbl_New_The_product_already_added_to_cart').subscribe((res: string) => {
          message = product.cName + " " + res;            
          });
          status = 'error';
        }        
        this.snackBar.open(message, cCart, { panelClass: [status], verticalPosition: 'top', duration: 3000 })
        .onAction()
        .subscribe(() => this.router.navigateByUrl('/cart'));
      });

      product.cartCount = this.count;
    }
  }
  
  
  public increment(count)
  {
 
    if(this.count < this.product.fProductStock){
      this.count++;
      let obj = {
        productId: this.product.nProductId,
        soldQuantity:this.count,
        total: this.count * this.product.fPrice,
        ncartid:this.product.nCartId,
        nBrandMomId:this.product.cRemarks3
      }
      this.appService.nQuantity=this.count;
      this.changeQuantity(obj);
    }
    else
    {
      //lbl_New_You_can_not_choose_more_items_than_available_In_stock
      var message;
      this.translate.get('lbl_New_You_can_not_choose_more_items_than_available_In_stock').subscribe(( res: string ) => 
                {
                  
                  message=res;
              }); 
      this.snackBar.open(message+' ' + this.count + ' items.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }    
  }

  public decrement(count){
    
    if(this.count > 1){
      this.count--;
      
      let obj = {
        productId: this.product.nProductId,
        soldQuantity: this.count,
        total: this.count * this.product.fPrice,
        ncartid:this.product.nCartId,
        nBrandMomId:this.product.cRemarks3
      }
      this.appService.nQuantity=this.count;
      this.changeQuantity(obj);
    }
  }


  public openProductDialog(event)
  {
    this.onOpenProductDialog.emit(event);
  }

  public changeQuantity(value)
  {
      this.onQuantityChange.emit(value);
  }

}