import { Component } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, UrlSegment, NavigationEnd } from "@angular/router"; 
import { Title } from '@angular/platform-browser';
import { Settings, AppSettings } from '../../../app.settings';
import { SidenavMenuService } from '../../../theme/components/sidenav-menu/sidenav-menu.service';
import { TranslateService} from "@ngx-translate/core";
import { AppService, Data } from '../../../app.service';
import { Location, LocationStrategy } from '@angular/common';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  providers: [ SidenavMenuService ]
})
export class BreadcrumbComponent {

    public pageTitle:string;
    public cPageTitle:string;
    public cPageCategory:string;
    public IsCheckUrl=0;
    public IsCheckUrlAll=0;
    public breadcrumbs: {
        name: string;
        url: string
    }[] = [];
    private sub: any;
    public settings: Settings;
    public isSersch=0;
    public isCheckProduct=0;
    constructor(public translate: TranslateService,public appSettings:AppSettings,
                public router: Router, 
                public activatedRoute: ActivatedRoute,                
                public title:Title,
                public appService:AppService, 
                public sidenavMenuService:SidenavMenuService,private location: Location,){


                    

            this.settings = this.appSettings.settings; 
            this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.breadcrumbs = [];                
                this.parseRoute(this.router.routerState.snapshot.root); 
                this.pageTitle = "";

                this.breadcrumbs.forEach(breadcrumb => {
                    //this.pageTitle += ' > ' + breadcrumb.name;
                    
                    // if(breadcrumb.name=="video")
                    // {
                    //     this.cPageTitle="Video Category";
                    // }
                    
                    
                    
                    

                    this.cPageCategory=breadcrumb.name;                  
                    this.isCheckProduct=0;
                    this.translate.addLangs(['en', 'ar']); 
                    this.translate.setDefaultLang(localStorage.getItem("DefaultLang"));  
                    if(breadcrumb.name=="About Us")
                    {
                        this.IsCheckUrl=0;
                           this.translate.get('lbl_New_aboutus_nav_title').subscribe(( res: string ) => {
                           this.cPageTitle=res;
                        });                       
                    }
                    
                    
                    if(breadcrumb.name=="All Products")
                    {

                        this.IsCheckUrl=1;
                           this.translate.get('lbl_All_Products').subscribe(( res: string ) => {
                         //  this.cPageTitle=res;
                        });    

                        this.cPageTitle="";                   
                    }
                    if(breadcrumb.name=="video")
                    {
                        this.IsCheckUrl=2;
                           this.translate.get('lbl_New_video_nav_title').subscribe(( res: string ) => {
                           this.cPageTitle=res;
                        });                       
                    }
                    if(breadcrumb.name=="VideoCategory")
                    {
                        this.IsCheckUrl=2;
                           this.translate.get('lbl_videocategory').subscribe(( res: string ) => {
                           //this.cPageTitle=res;
                           
                        });                       
                    }
                    if(breadcrumb.name=="Contact")
                    {
                        this.IsCheckUrl=0;
                           this.translate.get('lbl_New_contact_us_Nav_title').subscribe(( res: string ) => {
                           this.cPageTitle=res;
                        });                       
                    }
                    if(breadcrumb.name=="Dashboard")
                    {
                        this.IsCheckUrl=0;
                           this.translate.get('lbl_Dashboard').subscribe(( res: string ) => {
                           this.cPageTitle=res;
                        });                       
                    }
                    if(breadcrumb.name=="Dashboard")
                    {
                        this.IsCheckUrl=0;
                           this.translate.get('lbl_Dashboard').subscribe(( res: string ) => {
                           this.cPageTitle=res;
                        });                       
                    }
                    if(breadcrumb.name=="information")
                    {
                        this.IsCheckUrl=0;
                           this.translate.get('lbl_New_Account_Information').subscribe(( res: string ) => {
                           this.cPageTitle=res;
                        });                       
                    }
                    if(breadcrumb.name=="PasswordChange")
                    {
                        this.IsCheckUrl=0;
                           this.translate.get('lbl_New_setting_changePassword').subscribe(( res: string ) => {
                           this.cPageTitle=res;
                        });                       
                    }
                    if(breadcrumb.name=="Addresses")
                    {
                        this.IsCheckUrl=0;
                           this.translate.get('lbl_New_my_address_nav_title').subscribe(( res: string ) => {
                           this.cPageTitle=res;
                        });                       
                    }
                    if(breadcrumb.name=="Orders")
                    {
                        this.IsCheckUrl=0;
                           this.translate.get('lbl_New_setting_myOrders').subscribe(( res: string ) => {
                           this.cPageTitle=res;
                        });                       
                    }
                    if(breadcrumb.name=="Sign In")
                    {
                        this.IsCheckUrl=0;
                           this.translate.get('lbl_New_login_btn_login').subscribe(( res: string ) => {
                           this.cPageTitle=res;
                        });                       
                    }
                    if(breadcrumb.name=="Checkout")
                    {
                        this.IsCheckUrl=0;
                           this.translate.get('lbl_New_Bouquet_List_btn_Checkout').subscribe(( res: string ) => {
                           this.cPageTitle=res;
                        });                       
                    }
                    if(breadcrumb.name=="Wishlist")
                    {
                        this.IsCheckUrl=0;
                           this.translate.get('lbl_New_wishlist_Nav_title').subscribe(( res: string ) => {
                           this.cPageTitle=res;
                        });                       
                    }
                    if(breadcrumb.name=="Cart")
                    {
                        this.IsCheckUrl=0;
                           this.translate.get('lbl_New_side_menu_cart').subscribe(( res: string ) => {
                           this.cPageTitle=res;
                        });                       
                    }
                    if(breadcrumb.name=="Categories")
                    {
                            this.IsCheckUrl=1;
                            this.IsCheckUrlAll=1;
                           this.translate.get('lbl_New_categorylist_nav_title').subscribe(( res: string ) => {
                           this.cPageTitle=res;
                        });                       
                    }
                  
                    if(breadcrumb.url=="/subscription")
                    {
                        this.IsCheckUrl=0;
                           this.translate.get('lbl_New_Subscription_Main_lbl_tahani_subscription').subscribe(( res: string ) => {
                           this.cPageTitle=res;
                        });                       
                    }
                    if(breadcrumb.url=="/subscription/")
                    {
                        this.IsCheckUrl=0;
                           this.translate.get('lbl_New_Subscription_ProductList_Nav_title').subscribe(( res: string ) => {
                           this.cPageTitle=res;
                        });                       
                    }
                   
                    if(breadcrumb.name=="store list")
                    { 
                            this.IsCheckUrl=0;
                      
                           this.translate.get('lbl_New_StoreList_Nav_title').subscribe(( res: string ) => {
                            var  replaced = res.split('/').join('');
                            this.cPageTitle=replaced;
                            
                        });                       
                    }

                    if(breadcrumb.name=="store details")
                    {
                        this.IsCheckUrl=0;
                           this.translate.get('lbl_New_StoreDetails').subscribe(( res: string ) => {
                            var  replaced = res.split('/').join('');
                            this.cPageTitle=replaced;
                        });                       
                    }

                    if(breadcrumb.name=="bouquet")
                    {
                        this.IsCheckUrl=0;
                           this.translate.get('lbl_New_Select_Size_Chose_your_bouquet').subscribe(( res: string ) => {
                           this.cPageTitle=res;
                        });                       
                    }
                    if(breadcrumb.name=="subscrip-order")
                    {
                        this.IsCheckUrl=0;
                           this.translate.get('Subscribe-Order').subscribe(( res: string ) => {
                           this.cPageTitle=res;
                        });                       
                    }
                    
                    
                  


                    //lbl_category_list_title
                    //Cart

                    // else
                    // {
                    //     this.cPageTitle=breadcrumb.name;
                    // }
                    if(this.cPageCategory!=undefined)
                    {
                        this.cPageTitle=this.cPageCategory;                  
                    }
                    if(breadcrumb.name=="store-details")
                    {                        
                        var str = breadcrumb.url; 
                        var splitted = str.replace("store-details/",''); 
                        var final=splitted.replace("store-details/",'');
                        if(final !="store-details/")
                        {                           
                            this.cPageTitle=final.replace("/",'');
                            this.IsCheckUrl=3;
                        }                    
                    }
                   
                    if(this.IsCheckUrl==1)
                    {
                        
                        var str = breadcrumb.url; 
                        var splitted = str.replace("/products/",''); 
                        var serch = str.replace("/Search/",''); 
                        var serch = str.replace("/Search/",''); 
                        var final=splitted.replace("/Brands",'');
                        final=splitted.replace("/Category",'');
                       
                        if(final !="/products" && final !="/Category" && final !="/Brands")
                        {
                            var  replaced = final.split('-').join(' ');                           
                            var v=final.split('/').join('');
                            if(final=="Search/")
                            {
                                var  replaced = final.split('Search/').join('');                                
                            }
                            replaced = replaced.split('Search/').join('');
                            this.cPageTitle=replaced;
                            var serchitem=localStorage.getItem("serchitem");
                            var  replaced = final.split('Search/').join('');
                            this.isCheckProduct=0;
                            if(serchitem=="1")
                            {
                                this.cPageTitle="All Products";
                                this.isSersch=1;
                                localStorage.setItem("serchitem","0");
                            }
                            else
                            {
                                if(final !="/products" && final !="/Category" && final !="/Brands")
                                {
                                    this.isCheckProduct=1;
                                }
                            }
                            // this.appService.GetCategoryNameenglishandArabicForIdWise(final).subscribe(data=>
                            //     {
                            //         if(data[0].Success="1")
                            //         {
                                      
                            //             if(this.appService.nLanguageId==1)
                            //             {
                            //                 this.cPageTitle=data[0].result[0].EnglishCategoryName;
                            //             }
                            //             else
                            //             {
                            //                 this.cPageTitle=data[0].result[0].ArabicCategoryName;
                            //             }                                        
                            //         }
                            //     });
                        }
                        
                       
                    }
                   
                })   

                
                
                
               
               // this.title.setTitle(this.settings.name + this.pageTitle);
            }
        })   
    }

    private parseRoute(node: ActivatedRouteSnapshot) { 
        if (node.data['breadcrumb']) {
            if(node.url.length){
                let urlSegments: UrlSegment[] = [];
                node.pathFromRoot.forEach(routerState => {
                    urlSegments = urlSegments.concat(routerState.url);
                });
                let url = urlSegments.map(urlSegment => {
                    return urlSegment.path;
                }).join('/');

                if(node.params.name){
                    this.breadcrumbs.push({
                        name: node.params.name,
                        url: '/' + url
                    }) 
                }else{                
                    this.breadcrumbs.push({
                        name: node.data['breadcrumb'],
                        url: '/' + url
                    }) 
                }
            }         
        }
        if (node.firstChild) {
            this.parseRoute(node.firstChild);
        }
    }

    public closeSubMenus(){
        if(window.innerWidth < 960){
            this.sidenavMenuService.closeAllSubMenus();
        }    
    }

}