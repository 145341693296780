export class Category {
  constructor(public id: number, 
              public name:string, 
              public hasSubCategory: boolean,
              public parentId: number){ }
}

export class DeliveryType {
  constructor(public Delivery:string, 
              public Pickup: string){ }
}


export class Product {
  constructor(public id: number,
              public name: string,
              public images: Array<any>,
              public oldPrice: number,
              public newPrice: number,
              public discount: number,
              public ratingsCount: number,
              public ratingsValue: number,
              public description: string,
              public availibilityCount: number,
              public cartCount: number,
              public color: Array<string>,
              public size: Array<string>,
              public weight: number,
              
              public categoryId: number){ }
}

export class BouquetOrders {
  constructor(public cImage: string,
              public fPrice: Float32Array,
              public cCurrency: string,
              public nOrderId: number){ }
}
export class CreateOrder {
  constructor(public nUserId: number,
              public nCustId: number,
              public fTotal: Float32Array,
              public cPaymentTerms: string,
              public dtDelivaryDate: string,
              public cAddress: string,
              public cCountryCode: string,
              public cTime: string,
              public cCouponCode: string,
              public cJsonData: Array<any>,
              public cOrderNotes: string,
              public cAddressSpecialInstructions: string,
              public fDeliveryCharge: Float32Array,
              public cToken: string,
              public cBlock: string,
              public cStreet: string,
              public cAvenue: string,
              public cHouse: string,
              public cFloor: string,
              public cBuildingNo: string,
              public cName: string,
              public cMobileNo: string,
              public cAlterMobileNo: string,
              public cGovernorate: string,
              public cArea: string,
              public cSpecialInstruction: string,
              public nLanguageId: string,
              public cOrderType: string,
              public fCouponDiscount: string,
              public cFrom: string,
              public cTo: string,
              public cLinkGiftCard: string,
              public cMessage: string,
              public nBouquetSizeId: number,
              public nBouquetTypeId: number,
              public cDeviceName: string,
              public cAppVersion: string){ }
}


export class Person {
  constructor(public nUserId: number,
              public FileType: string,
              public nLanguageId: number,            
              public cToken: string,
              public File: string){ }
}


export class comments{
  postId: number;
  id: number;
  name: string;
  email: string;
  body: string;
}

export class ResponseData{
  constructor(public Success: number,
              public Result: Object){}
}

export class ResponseDatasmallr{
  constructor(public Success: number,
              public result: Object){}
}


export class ResponseData_Wishlist{
  constructor(public Success: number,
              public Message: string,
              public WishListId: number
              ){}
}
export class Vendors {
  constructor(public nCustomerId: number,
              public cCustomerType: string,
              public cCustomerFirstName: string,
              public cCustomerMiddleName: string,
              public cCustomerLastName: string,
              // public images: Array<any>,
              public cCustomerContactNo: string,
              public cCustomerEmailId: string,
              public cCustomerCompany: string,
              public cCustomerAddress: string,
              public cCity: string,
              public cZipCode: string,
              public cCustomerWebsite: string,
              public cCustomerImage: string,
              public dtCustomerBirthdate: Date,
              public dtRegistrationDate: Date,
              public nUserId: number,
              public nLanguageId: number){ }
}

export class Category_SubCategory {
  constructor(public id: number, 
              public name:string, 
              public hasSubCategory: boolean,
              public parentId: number){ }
}

export class BouquteList {
  constructor(public nProductId: number, 
              public cName:string, 
              public cCategoryName:string,
              public fPrice:any,
              public cShortDescription:string,
              public dtSpecialPriceFrom:string,
              public dtSpecialPriceTo:string,
              public fSpecialPrice:Float32Array,
              public cImage:string,
              public Currency: string,
              public nQuantity:number,
              public fStock: Float32Array){ }
}

export class BouquteListStore {
  constructor(public nProductId: number,
              public nQuantity:number,
              public Type:string
              ){ }
}

export class BouquetSize {
  nBouquetSizeId: any;
  cName: string;
  fPrice:any;
  fSpecialPrice:any;
  cImage:any;
  cDescription:any;
  nMainCount:any;
  nFillerCount:any;
  nLanguageId:any;
  nProductId:any;
  Currency:any;
}



export class StoreList {
  constructor(public nStoreId: number,
              public cStoreName: string,
              public cStoreURL: string,             
              public cStoreLogo: string,
              public cStoreLocation: string,
              public cStoreLetitude: string, 
              public cStoreLongitude: string, 
              public cStoreContactNo: string, 
              public cStoreEmail: string, 
              public cStoreTaxNo: string,             
              public cStoreBillFormate: string, 
              public cStoreLocationType: string, 
              public nDefaultCurrency: number, 
              public nDefaultLanguage: number, 
              public IsActive: boolean, 
              public IsDisable: boolean, 
              public nLanguageId: number, 
              public cRemarks: string, 
              public cRemarks1: string, 
              public cRemarks2: string, 
              public cRemarks3: string, 
              public cCurrencyName: string, 
              public nParentStoreId: number, 
              public cCityName: string,
              public cAreaName: string){ }
}

export class Categories {
  constructor(public nCategoryId: number,
              public nParentCateogyId: number,
              public nCateogyLangWiseId: number,
              public cCategoryName: string,
              public cCategoryDesc: string,             
              public cCategoryIcon: string,
              public cCategoryImage: string,
              public IsSubCategory: number,             
              public nLanguageId: number){ }
}



export class ProductItem {
  constructor(
   // public length:number,
    public nProductId: number,
              public cName: string,
              public cDescription: string,
              public cShortDescription: string,
              public fPrice: Float32Array,             
              // public images: Array<any>,
              public cImage: string,
              public nSupplierId: number,
              public nBrandAndMomsId: number,
              public cCustomerName: string){ }
}

export class CountryName {
  constructor(
   // public length:number,
              public nCityId: number,
              public cCityName: string,
              public cCityCode: string){ }
}

export class GetAddress {
  constructor(
   // public length:number,
              public nAddressId: number,
              public cName: string,
              public cBlock: string,
              public cStreet: string,
              public cHouse: string,
              public cFloor: string,
              public cBuildingNumber: string,
              public cMobileNo: string,
              public cAddress: string,
              public cAlternateMobileNo: string,
              public cSpecialInstruction: string,
              public nCustomerId: number,
              public cAreaName: string,
              public cCityName: string,
              public cCountryName: string,
              public cAddressType: string,
              public cLandmark: string,
              public cBuildingName: string,
              public cOfficeName: string){ }
}

export class AreaName {
  constructor(
   // public length:number,
              public nAreaId: number,
              public cAreaName: string,
              public cAreaCode: string){ }
}
export class ProductDetails {
  constructor(public nProductId: number,
              public cName: string,
              public cDescription: string,
              public cShortDescription: string,
              public fPrice: number,
              public cartCount: number,
              public fStock: number,
              public nQuantity:number,
              public fProductStock :number,
              public Attribute: Array<any>,
              public Images:Array<any>,
              public cImage: string,
              public nSupplierId: number,
              public fFinalStock: number){ }
}


export class ProductReviewDetails {
  constructor(public nReviewId: number,
              public nCustomerId: number,
              public nTypeId: number,
              public nUserId: number,
              public cReview: string,
              public cReviewFor: string,
              public nReviewRating: number,
              public cName:string,
              public cImage:string,
              
              public ReviewPlayList: Array<any>){ }
}
export class Review {
  constructor(public nReviewId: number,
    public nCustomerId: number,
    public nTypeId: number,
    public nUserId: number,
    public cReview: string,
    public cReviewFor: string,
    public nReviewRating: number,
    public cName:string,
    public cImage:string){ }
}

export class cartlist {
  constructor(public nCartId: number,
    public nCustomerId: number,
    public nProductId: number,
    public nUserId: number,
    public dtCartDate: string,
    public IsActive: boolean,
    public IsDisable: boolean,
    public cRemarks1: string,
    public cRemarks2: string,
    public cRemarks3: string,
    public nQuantity: number,
    public fQuantity: number,
    public cCustomerName: string,
    public cProductName: string,
    public nAttributeSetId: number,
    public nOptionTemplatesId: number,
    public cName: string,
    public cDescription: string,
    public cShortDescription: string,
    public cSKU: string,
    public nWeight: number,
    public fPrice: number,
    public dPrice: number,
    public fSamplePrice: number,
    public nSpecialPrice: number,
    public fSpecialPrice: number,
    public dtSpecialPriceFrom: string,
    public dtSpecialPriceTo: string,
    public cMetaTitle: string,
    public cMetaDescription: string,
    public nBaseCurrencyId: number,
    public nParentProductId: number,
    public nTaxId: number,
    public cCsvFile: string,
    public cUrlKey: string,
    public dtCreatedDate: string,
    public nLanguageId: number,
    public cRemark1: string,
    public nCategoryId: number,
    public cRemark2: string,
    public cRemark3: string,
    public cImage: string,
    public nMinimumStockAlert: number,
    public IsTrackInventory: boolean,
    public IsVirualProduct: boolean,
    public IsFeatured: boolean,
    public dtNewProductFrom: string,
    public dtNewProductTo: string,
    public cWarrantyType: string,
    public nWarrentyDuration: number,
    public nWarrentyId: number,
    public cProductCode: string,
    public cProductUniqueCode: string,
    public fMRP: number,
    public nUnitType: number,
    public Currency: string,
    public fProductStock: number,
    public fStock: number,
    public fProducFeelIttStock: number,
    public MinimumOrderQuantity: string,
    public nCartType: number,
    public IsBundleProduct: number,
    public Attribute?: null,
    public cCategoryName?: null,
    public IswishList?: number,
    public cTaxName?: null){}
}

export class WishlistProduct {
  constructor(public nProductId: number,
              public cName: string,
              public cDescription: string,
              public cShortDescription: string,
              public fPrice: Float32Array,             
              // public images: Array<any>,
              public cImage: string,
             // public nSupplierId: number
             public nSupplierId: number,
              public nBrandAndMomsId: number,
              public cCustomerName: string){ }
}

   export class ParametersAPI
   {
     constructor
     (public nUserId: number,
      public nLanguageId: number,
      public cToken: string,
      )
      { }
   } 
    
   export class Wishlist
   {
     constructor
     (public nUserId: number=1,
      public nLanguageId: number=1,
      public cToken: string='fCGiIPgXbJ1q9Axf4zuA',
      public cWishList: string='1',
      public nVendorId: number=1,
      public nProductId: number=39
      
      )
      { }
   } 
