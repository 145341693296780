import { Renderer2, Component, OnInit, HostListener, ViewChild,Inject } from '@angular/core'; 
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from '../app.settings';
import { AppService } from '../app.service';
import { Category, Product } from '../app.models';
import { SidenavMenuService } from '../theme/components/sidenav-menu/sidenav-menu.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService} from "@ngx-translate/core";
import { FormsModule } from '@angular/forms';
import { LoaderService } from '../shared/service/loader.service';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [ SidenavMenuService ]
})

export class PagesComponent implements OnInit 
{
  username: string = '';

  public showBackToTop:boolean = false; 
  public categories:Category[];
  public category:Category;
  public sidenavMenuItems:Array<any>;
  public currencysym="KWD";
  public currencies = ['USD', 'EUR'];
  public currency:any;
  public cUserName=localStorage.getItem("name");
  public cuserImage=localStorage.getItem("CustomerImage");
  public nCustomerId=localStorage.getItem("nCustomerId");
  public cSearchProductData:any;
  public categoryList:any;
  public flags = [
    { name:'English', image: 'assets/images/flags/gb.svg' },
    { name:'German', image: 'assets/images/flags/de.svg' },
    { name:'French', image: 'assets/images/flags/fr.svg' },
    { name:'Russian', image: 'assets/images/flags/ru.svg' },
    { name:'Turkish', image: 'assets/images/flags/tr.svg' }
  ]
  public flag:any;
  @ViewChild('sidenav', { static: true }) sidenav:any;

  public Email:any;
  public SupportEnquiry:any;
  public settings: Settings;
  public href: string = "";
  public isCheckCheckout="0";
  constructor(public translate: TranslateService,public appSettings:AppSettings, 
              public appService:AppService, 
              public sidenavMenuService:SidenavMenuService,
              public router:Router,              
              public snackBar: MatSnackBar,
              private loaderService: LoaderService,private _renderer2: Renderer2,
              @Inject(DOCUMENT) private _document: Document) { 
    this.settings = this.appSettings.settings; 
  }

  async ngOnInit() 
  {
    
    this.isCheckCheckout="0";
    this.loaderService.display(true);
    this.Email="orders@tahaniflowers.com";//localStorage.getItem("EmailAddress");
    this.SupportEnquiry="+96566319497";localStorage.getItem("SupportEnquiry");
    this.getUserDetails();
    this.appService.setLanguage();
    this.getCategoryList();
    var LanguageName=localStorage.getItem("DefaultLang");
    if(LanguageName!=null && LanguageName!=undefined)
    {
      localStorage.getItem("nLanguageId");  
    }
    else
    {    
    
      localStorage.setItem("nLanguageId","1");
      localStorage.setItem("DefaultLang","en");
    }
   // this.translate.addLangs(['en', 'ar']); 
     this.translate.setDefaultLang(localStorage.getItem("DefaultLang"));  

     
    this.getCategories();
    this.flag = this.flags[0];
  


   
    
    var cdata=await this.sidenavMenuService.getSidenavMenuItems();
    if(cdata!=null && cdata !=undefined)
    {
      this.sidenavMenuItems =cdata;
    }
    
    //await this.getUserProfile();
    //this.sidenavMenuItems=await this.sidenavMenuService.getUserProfile();
   
    this.getCartByCustomerId();
    this.loaderService.display(false);
    this.appService.fbLibrary();
  } 



  public getCategoryList()
  {
    if(this.appService.IscheckOneTime==0)
        {
          
          this.appService.GetMainandSubCategory().subscribe(data => 
            {
              if(data[0].Success=="1")
              {     
                this.appService.lstCategories=data[0].result;
                this.appService.IscheckOneTime=1;   
                 this.categoryList=data[0].result;
               }
            });
        }
  }



  public onCheckOutData()
  {
    this.isCheckCheckout="1"; 
  }
public onShow()
{
  document.getElementById("overlay").style.display = "block";
}
  public getUserDetails()
  {
    this.nCustomerId=localStorage.getItem("nCustomerId");
    if(this.nCustomerId==null)
    {
      this.nCustomerId="0";
    }
   
    this.cUserName=localStorage.getItem("name");
    this.cuserImage=localStorage.getItem("CustomerImage");

   
  }
  

  public getCartByCustomerId()
  {
       
    
    this.appService.Data.totalPrice = 0;
    this.appService.Data.totalCartCount = 0;

    let cDeviceToken=localStorage.getItem('cDeviceToken');
       
    let nCustomerIdPass=0;
    if(localStorage.getItem('nCustomerId')){
      if(Number.parseInt(localStorage.getItem('nCustomerId'))>0){
        nCustomerIdPass=Number.parseInt(localStorage.getItem('nCustomerId'));
        cDeviceToken="";
      }
    }

    let isCall=false;
    if(cDeviceToken){
     isCall=true;
    }
    if(nCustomerIdPass>0){
      isCall=true;
    }

    if(isCall)
    {
      
      this.appService.SelectAllCartProductByCustomerV2(nCustomerIdPass,cDeviceToken,1).subscribe(data => {
       
        if(data[0].Success == '1')
        {          
          
          if(data[0].Result.length>0){

            this.appService.Data.cartList=data[0].Result;
            
            this.appService.Data.totalPrice=data[0].fSubTotal;
            this.appService.Data.totalCartCount=data[0].Result.length;
            this.currencysym=data[0].Result[0].Currency;
          }
          else{
            this.appService.Data.cartList=[];
            this.appService.Data.totalPrice=0;
            this.appService.Data.totalCartCount=0;
          }
          //return this.Data.cartList;
          // this.Data.cartList.forEach(ProductDetails=>{
            //     this.Data.totalPrice = this.Data.totalPrice + (ProductDetails.cartCount * ProductDetails.fPrice);
            //     this.Data.totalCartCount = this.Data.totalCartCount + ProductDetails.cartCount;
          // });
        }
        else
        {
          
            this.appService.Data.cartList=[];
            this.appService.Data.totalPrice=0;
            this.appService.Data.totalCartCount=0;
            this.href = this.router.url;
            if(this.appService.Data.cartList.length==0)
            {
              if(this.href=="/checkout/1")
              {
                this.isCheckCheckout="1";
              }
            }
        }
      });
    }    
  }

  public getCategories(){    
    this.appService.getCategories().subscribe(data => {
      this.categories = data;
      this.category = data[0];
      this.appService.Data.categories = data;
    })
  }

  public changeCategory(event){
    if(event.target){
      this.category = this.categories.filter(category => category.name == event.target.innerText)[0];
    }
    if(window.innerWidth < 960){
      this.stopClickPropagate(event);
    } 
  }

  public remove(product) {

    if(product.nCartId > 0){

      this.appService.DeleteCartRecord(product.nCartId ).subscribe(data => {
        if(data[0].Success == '1')
        {

          this.getCartByCustomerId();
         localStorage.setItem("isReloadcart","0");
          var status = 'error'; 
          var message=''
          this.translate.get('lbl_New_Itemremovedsuccessfully').subscribe((res: string) => {

            message = product.cName + " " + res;
          });
          message = message;
          status = 'error';
        }
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
        //this.WishlistResponse = data[0]; 
          //this.snackBar.open("{{'lbl_New_Itemremovedsuccessfully' | translate }}", '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
        //}
      });
    }

    //this.appService.getCart();
      // const index: number = this.appService.Data.cartList.indexOf(product);
      // if (index !== -1) {
      //     this.appService.Data.cartList.splice(index, 1);
      //     this.appService.Data.totalPrice = this.appService.Data.totalPrice - product.newPrice*product.cartCount;
      //     this.appService.Data.totalCartCount = this.appService.Data.totalCartCount - product.cartCount;
      //     this.appService.resetProductCartCount(product);
      // }        
  }

  public clear(){
    this.appService.getCart();
    // this.appService.Data.cartList.forEach(product=>{
    //   this.appService.resetProductCartCount(product);
    // });
    // this.appService.Data.cartList.length = 0;
    // this.appService.Data.totalPrice = 0;
    // this.appService.Data.totalCartCount = 0;
  }
 

  public changeTheme(theme){
    this.settings.theme = theme;       
  }

  public stopClickPropagate(event: any){
    event.stopPropagation();
    event.preventDefault();
  }

  public search(){}

 
  public scrollToTop(){
    var scrollDuration = 200;
    var scrollStep = -window.pageYOffset  / (scrollDuration / 20);
   
    var scrollInterval = setInterval(()=>{
      if(window.pageYOffset != 0){
         window.scrollBy(0, scrollStep);
      }
      else{
        clearInterval(scrollInterval); 
      }
    },10);
    if(window.innerWidth <= 768){
      setTimeout(() => { window.scrollTo(0,0) });
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;  
  }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) { 
        this.sidenav.close(); 
      }                
    });
    //this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());
  }

  public closeSubMenus(){
    if(window.innerWidth < 960){
      this.sidenavMenuService.closeAllSubMenus();
    }    
  }


  public Logout()
  {
   
    localStorage.setItem('nCustomerId','0');
    localStorage.setItem('name','');
    
    //attachSignin
    window['FB'].getLoginStatus(function(response) 
    {
     
      if (response.status === 'connected') {
        // The user is logged in and has authenticated your
        // app, and response.authResponse supplies
        // the user's ID, a valid access token, a signed
        // request, and the time the access token 
        // and signed request each expire.
        var uid = response.authResponse.userID;
        var accessToken = response.authResponse.accessToken;
        window["FB"].Event.subscribe('auth.logout', window["FB"].logout());
        location.reload();
      } else if (response.status === 'not_authorized') 
      {
        this.router.navigate(['/']); 
         location.reload();
        // The user hasn't authorized your application.  They
        // must click the Login button, or you must call FB.login
        // in response to a user gesture, to launch a login dialog.
      } else {
        // The user isn't logged in to Facebook. You can launch a
        // login dialog with a user gesture, but the user may have
        // to log in to Facebook before authorizing your application.
      }
     });
    //this.appService.attachSignin(document.getElementById('googleBtn'));
    // let cToken=localStorage.getItem("FBTokem");
    // let UserId=localStorage.getItem("id");
    // this.appService.fbLibrary();    
    location.reload();
    
   
     
  }



  public searchProduct()
  {
    document.getElementById("searchdata").className= "search_bar show";
  }

  public SearchProductData(values)
  {    
    localStorage.setItem("serchitem","1");
    this.router.navigate(['../../../products','Search',values]); 
  }

  hyphenateUrlParams(str:string)
  {
    var ccategoryName = str.toLowerCase();
    var replaced = ccategoryName.split('-').join(' ');
    replaced = replaced.split(' ').join('-');
    return replaced.toLowerCase();
  }

  public changeroute(id)
  {
    localStorage.setItem("nCategoryId",id);
  }

}