import { Component, ViewEncapsulation, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Data, AppService } from '../../../app.service';
import { Product } from '../../../app.models';
import { LoaderService } from '../../service/loader.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductDialogComponent implements OnInit {

  enid : string='';


  public config: SwiperConfigInterface = {};
  constructor(public appService:AppService,private loaderService: LoaderService,private router: Router, 
              public dialogRef: MatDialogRef<ProductDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public product: Product) { }

  ngOnInit() { }

  ngAfterViewInit(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true, 
      effect: "fade",
      fadeEffect: {
        crossFade: true
      }
    }
  }

  public close(): void {
    this.dialogRef.close();
  }
  
  hyphenateUrlParams(str:string)
  {
    var ccategoryName = str.toLowerCase();
    var replaced = ccategoryName.split('-').join(' ');
    replaced = replaced.split(' ').join('-'); 
    return replaced.toLowerCase(); 
  }
  public changeroute(product : any)
  {
    //localStorage.setItem("nProductId",id);
    this.enid = this.loaderService.encryptUsingAES256(product.nProductId);
    const hyphenatedCName = this.hyphenateUrlParams(product.cName);
    this.router.navigate(['/products', hyphenatedCName], { queryParams: { id: this.enid } });
  }
}