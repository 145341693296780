import { Component, ChangeDetectionStrategy,OnInit, ChangeDetectorRef  } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppService} from './app.service';
import { Settings, AppSettings } from './app.settings';
import { LoaderService } from './shared/service/loader.service';
import {AnalyticsServiceService} from './analytics-service/analytics-service.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
 
})
export class AppComponent implements OnInit {
  Food ="{name: 'vanita'}"
  showLoader: boolean;

  loading: boolean = false;
  public settings: Settings;
  constructor(public appSettings:AppSettings,public appService:AppService,
     public router: Router, private loaderService: LoaderService,
      private readonly analyticsService: AnalyticsServiceService,
      private cdr: ChangeDetectorRef
   ){
    this.settings = this.appSettings.settings;
    
   
  }


  
  ngOnInit() 
  {
    localStorage.setItem("IsSetBack","1");
    this.analyticsService.init();
    this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    
      
  });
  
  this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      (window as any).ga('set', 'page', event.urlAfterRedirects);
      (window as any).ga('send', 'pageview');
    }
  });
    var LanguageName=localStorage.getItem("LanguageName");
  
    // this.appService.setLanguage();
    // this.appService.getSlidesList();
    // //this.appService.getBrandsList();
    // this.appService.AllBrandList();
   
      
  }
 
 

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          window.scrollTo(0,0);
      }
    })  
    this.cdr.detectChanges();
  }
  
  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

}
