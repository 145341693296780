import { Component, OnInit,Input,Pipe,PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreList } from "../../app.models";
import { AppService, Data } from '../../app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService} from "@ngx-translate/core";
import { LoaderService } from '../../shared/service/loader.service';
@Component({
  selector: 'app-store-details',
  templateUrl: './store-details.component.html',
  styleUrls: ['./store-details.component.scss']
})
export class StoreDetailsComponent implements OnInit {
  private sub: any;
  public _Id:any;
  public cStoreName:any;
  public cUrl:any;
  public srclat:any;
  @Input('StoreList') StoreList: Array<any> = [];
  constructor(public translate: TranslateService,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
     public appService:AppService
     ,private loaderService: LoaderService ) { }
  lstStoreList:  Array<StoreList> = [];
  ngOnInit(): void 
  {
    this.translate.addLangs(['en', 'ar']);  
    this.translate.setDefaultLang(localStorage.getItem("DefaultLang"));  
    this.srclat =this.sanitizer.bypassSecurityTrustResourceUrl("https://maps.google.com/maps?q=29.305904,47.939866&t=&z=20&ie=UTF8&iwloc=&output=embed");
    this.sub = this.activatedRoute.params.subscribe(param => 
    {
      this._Id=localStorage.getItem("nStoreId");
      this.loaderService.display(true);
      this.getStoreDetails();
    }); 
  }
  public getStoreDetails(){
    this.appService.SelectAllStoreById(this._Id).subscribe(data => {   
      var datalist=[];  
     if(Boolean){
      if(data[0].Success==1)
      {
       
        this.lstStoreList = data[0].result; 
        this.cUrl=this.sanitizer.bypassSecurityTrustResourceUrl("https://maps.google.com/maps?q="+data[0].result[0].cStoreLetitude+","+data[0].result[0].cStoreLongitude+"&t=&z=20&ie=UTF8&iwloc=&output=embed");
        //this.cUrl='http://maps.google.com/maps?q='+data[0].result[0].cStoreLetitude+','+data[0].result[0].cStoreLongitude+'&z=15&output=embed';
        this.loaderService.display(false);
      }
      else
      {
        this.loaderService.display(false);
      }
     }        
    });
  }

}
