import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {
  @Input('banners') banners: Array<any> = [];
  
  constructor() { }
public ischeckdata=0;
  ngOnInit() 
  {   
    
  }

  public getBanner(index)
  {  
    return this.banners[index];
  }

  // public getBgImage(index){
  //   let bgImage = {
  //     'background-image': index != null ? "url(" + this.banners[index].image + ")" : "url(https://via.placeholder.com/600x400/ff0000/fff/)"
  //   };
  //   return bgImage;
  // } 

  hyphenateUrlParams(str:string)
  {
    var ccategoryName = str.toLowerCase();
    var replaced = ccategoryName.split('-').join(' ');
    replaced = replaced.split(' ').join('-'); 
    return replaced.toLowerCase();
  }

  public changeroute(id)
  {
   localStorage.setItem("nCategoryId",id);
  }
}
