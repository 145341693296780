import { Component, OnInit, Input} from '@angular/core';
import { Data, AppService } from '../../../app.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  
  constructor(public appService:AppService) { }
  public lstCategories:any;
  ngOnInit() 
  {
    //this.GetMainandSubCategory(); 
  }


  

  public GetMainandSubCategory()
  {
    
    this.appService.GetMainandSubCategory().subscribe(data => 
    {     
      if(data[0].Success==1)
      {        
        this.lstCategories=data[0].result;        
      }
          
    });
  }


  openMegaMenu(){
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
        if(el.children.length > 0){
          if(el.children[0].classList.contains('mega-menu')){
            el.classList.add('mega-menu-pane');
          }
        }        
    });
  } 

  hyphenateUrlParams(str:string)
  {
    var ccategoryName = str.toLowerCase();
    var replaced = ccategoryName.split('-').join(' ');
    replaced = replaced.split(' ').join('-');
    return replaced.toLowerCase();
  }

  public changeroute(id)
  {
    localStorage.setItem("nCategoryId",id);
  }
}
