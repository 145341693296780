import { Component, OnInit } from '@angular/core';
import { Data, AppService } from '../../../app.service';
import { Settings, AppSettings } from '../../../app.settings';
import { ActivatedRoute, Router } from '@angular/router';
import { Product, ProductDetails, ProductItem } from '../../../app.models';
import { TranslateService } from '@ngx-translate/core';
@Component({
  //selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  
  selector: 'app-top-menu',
 
})
export class TopMenuComponent implements OnInit {
  public currencies = ['USD', 'EUR'];
  public currency:any;
  private sub: any;
  public page:any;
  public settings: Settings;
  nGovernorateId:any;
  public cdata=this.appService.getAllLanguage();
  public cCountryData=this.appService.getAllCountry();
  public flags=this.appService.flags;
  public Countrys=this.appService.Countrys;
  // public flags = [
  //   { name:'English', image: 'assets/images/flags/gb.svg' },
  //   { name:'German', image: 'assets/images/flags/de.svg' },
  //   { name:'French', image: 'assets/images/flags/fr.svg' },
  //   { name:'Russian', image: 'assets/images/flags/ru.svg' },
  //   { name:'Turkish', image: 'assets/images/flags/tr.svg' }
  // ]
  public flag:any;
  public CountryName:any;
  lstProductWishlist: ProductItem;
  nWishlistCount:Number;
  name:any;
  nCustomerId:any;
 // public settings: Settings;
  constructor(public translate: TranslateService,public appSettings:AppSettings, 
    private activatedRoute: ActivatedRoute, 
    public appService:AppService,
    private router: Router    
    ) {    
    this.nWishlistCount=0;
    this.settings = this.appSettings.settings;
    //translate.addLangs(['en', 'ar']);  
    translate.setDefaultLang('en');   
  } 

  ngOnInit() {
    
    this.sub = this.activatedRoute.params.subscribe(param => 
      {
        let nCustomerId=localStorage.getItem('nCustomerId'); 
        if(nCustomerId !="0" && nCustomerId !=undefined && nCustomerId !=null)
        {
          this.appService.getWishlistByCustomerId(nCustomerId);  
        }        
       
        this.name=localStorage.getItem("name");
        this.nCustomerId=localStorage.getItem('nCustomerId');
    });
    //this.appService.nCustomerId
   
    this.currency = this.currencies[0];
    var LanguageName=localStorage.getItem("LanguageName");
    
    if(LanguageName!=null && LanguageName!=undefined)
    {
      this.flag = localStorage.getItem("LanguageName");  
      if(localStorage.getItem("LanguageName") !="English")
      {
        this.settings.rtl = true; 
      }
      else
      {
        this.settings.rtl = false; 
      }
    }
    else
    {
      this.flag="English";      
      localStorage.setItem("nLanguageId","1");
      this.settings.rtl = false; 
    }
   
    var cCountryName=localStorage.getItem("cCountryName");
    if(cCountryName !="" && cCountryName !=null)
    {
      this.CountryName=localStorage.getItem("cCountryName");
    }
    else
    {
      this.CountryName="KUWAIT";
    }
    
    
    
  }

//   public getWishlistByCustomerId(nCustomerId){ 
//     let cDeviceToken=localStorage.getItem('cDeviceToken');   
//     this.appService.getWishlistByCustomer(nCustomerId).subscribe(data => {
//       if(Boolean(data)){
//         if(data[0].Success==1){         
//           this.nWishlistCount=data[0].result.length;
//           this.appService.Data.wishList.length=data[0].result.length;
//        
          
//         } 
//       }
//   });
// }
  public changeCurrency(currency){
    this.currency = currency;
  }

  switchLang(lang: string) {  
    this.translate.use(lang);  
   
 }  

  public changeLang(flag){
   // this.flag = flag;
  
    this.nGovernorateId=flag.name;
    this.appService.nLanguageId=flag.nLanguageId;
    var nLanguageId=flag.nLanguageId;
    localStorage.setItem("LanguageName",flag.name);
    localStorage.setItem("nLanguageId",nLanguageId);
    var lat= this.appSettings.settings.rtl=false;
    //this.settings =this.appSettings.settings.rtl=false;
    
   


    
    
    if(localStorage.getItem("LanguageName") !="English")
    {
      this.settings.rtl = true; 
    }
    else
    {
      this.settings.rtl = false; 
    }

    var DefaultLang=flag.cLanguageCssType;
     //this.translate.use("ar");  
    
    localStorage.setItem("DefaultLang",DefaultLang);
    this.appService.nLanguageId=flag.nLanguageId;
    //this.changeTheme("ltr")
    location.reload() ;
    
  }


  public chnageCountry(country)
  {   
    this.CountryName=country.name;
    localStorage.setItem("nCountryId",country.nCountryId);
    localStorage.setItem("cCountryName",country.name);
    location.reload();     
   }

  public changeType(cTypeBabyMumz){
    this.appService.Data.cTypeBabyMumz = cTypeBabyMumz;
  
    this.router.navigate(['/']); 
  }
  public onRegisterFormSubmit() {
   
    localStorage.setItem('nCustomerId','0');
    localStorage.setItem('name','');
    
    location.reload() 
  }

  public changeTheme(theme)
  {
    
    this.settings.theme = theme;       
  } 
}
