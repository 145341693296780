import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
//import { ProductHomeComponent } from './product-home/product-home.component';
import { Data, AppService } from '../../app.service';
import { ProductDialogComponent } from '../products-carousel/product-dialog/product-dialog.component';
import { Product } from "../../app.models";
import { Settings, AppSettings } from 'src/app/app.settings';
import { LoaderService } from '../service/loader.service';

@Component({
  selector: 'app-products-home',
  templateUrl: './products-home.component.html',
  styleUrls: ['./products-home.component.scss']
})
export class ProductHomeComponent implements OnInit {
  enid : string='';
  @Input('products') products: Array<Product> = [];
  public config: SwiperConfigInterface = {};
  public settings: Settings;
  public viewType: string = 'grid';
  constructor(public appSettings:AppSettings, 
    private loaderService: LoaderService,
    public appService:AppService, public dialog: MatDialog, private router: Router) { 
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {}
  
  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 16,       
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,  
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
        1500: {
          slidesPerView: 5,
        }
      }
    }
  }

  public openProductDialog(product){   
 
    let dialogRef = this.dialog.open(ProductDialogComponent, {
        data: product,
        panelClass: 'product-dialog',
        direction: (this.settings.rtl) ? 'rtl' : 'ltr'
    });
    dialogRef.afterClosed().subscribe(product => {
      if(product){
        this.router.navigate(['/products', product.nProductId]); 
      }
    });
  }

  hyphenateUrlParams(str:string)
  {
    var ccategoryName = str.toLowerCase();
    var replaced = ccategoryName.split('-').join(' ');
    replaced = replaced.split(' ').join('-'); 
    return replaced.toLowerCase();
  }

  public changeroute(product : any)
  {
    
    //localStorage.setItem("nProductId",id);
    this.enid = this.loaderService.encryptUsingAES256(product.nProductId);
    const hyphenatedCName = this.hyphenateUrlParams(product.cName);
    this.router.navigate(['/products', hyphenatedCName], { queryParams: { id: this.enid } });
  }

}