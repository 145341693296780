import { Component, OnInit ,Input} from '@angular/core';
import { Router } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
//import { ProductHomeComponent } from './product-home/product-home.component';
import { Data, AppService } from '../../app.service';
import { BouquetOrders } from "../../app.models";
import { Settings, AppSettings } from 'src/app/app.settings';
@Component({
  selector: 'app-bouquet-order',
  templateUrl: './bouquet-order.component.html',
  styleUrls: ['./bouquet-order.component.scss']
})
export class BouquetOrderComponent implements OnInit {
  @Input('BouquetOrders') BouquetOrders: Array<BouquetOrders> = [];
  public config: SwiperConfigInterface = {};
  public settings: Settings;
  public viewType: string = 'grid';
  constructor(public appSettings:AppSettings, public appService:AppService, public dialog: MatDialog, private router: Router) 
  {
    this.settings = this.appSettings.settings; 
   }

  ngOnInit(): void {}

  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 16,       
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,  
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
        1500: {
          slidesPerView: 5,
        }
      }
    }
  }

}
